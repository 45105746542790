<template>
  <tr v-if="headers?.length" class="gw-input-filters">
    <template v-for="(header, idx) in headers">
      <td :key="`${header}-${idx}`">
        <slot :apply="filter" :name="header" />
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import {
  Component,
  Mixins,
  Prop,
  Ref,
  VModel,
  Watch,
} from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import FiltersMixin from "@/mixins/FiltersMixin";
import type { VForm } from "vuetify/lib";
import IconOptionsOutline from "@/components/icons/IconOptionsOutline.vue";
import { EventBus } from "@/services/event-bus";
import IconRefreshCw from "@/components/icons/IconRefreshCw.vue";
import { delay } from "lodash";

@Component({
  components: {
    IconRefreshCw,
    IconOptionsOutline,
  },
})
export default class InputFilters extends Mixins(GlobalMixin, FiltersMixin) {
  @VModel({ type: Object, default: () => ({}) }) obFilters!: Record<
    string,
    any
  >;
  @Ref() readonly form!: InstanceType<typeof VForm>;
  @Prop(Boolean) readonly clearOnCreate!: boolean;
  @Prop(Boolean) readonly localLoading!: boolean;
  @Prop(Boolean) readonly noVuex!: boolean;
  @Prop(Array) readonly headers!: string[];

  @Prop(String) readonly modelName!: string;

  isDirty = false;

  obOldFilters: Record<string, any> = {};

  @Watch("obFilters", { deep: true })
  onWatchDirty() {
    this.isDirty = true;
  }

  mounted() {
    this.sync();
  }

  created() {
    if (this.clearOnCreate && !this.noVuex) {
      this.onSetFilters({});
    }
  }

  filter() {
    if (!this.noVuex) {
      this.onSetFilters(this.obFilters);
    }

    this.sync();

    this.$emit("apply", true);
  }

  /**
   * Copy filters to old filters var
   */
  sync(): void {
    delay(() => {
      this.isDirty = false;
      // this.obOldFilters = { ...this.obFilters };
    }, 500);
  }

  onReload() {
    EventBus.emit("reload.index");
  }
}
</script>
