<template>
  <v-select
    v-model="dgiStatus"
    :items="arOptions"
    :menu-props="{ offsetY: true }"
    hide-details="auto"
    item-text="text"
    item-value="value"
    v-bind="obAttrs"
    @change="onChange"
  />
</template>

<script lang="ts">
import { defaults } from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class InvoiceDgiStatusSelect extends Vue {
  arOptions: any[] = [
    { text: this.$t(`all`), value: "all" },
    { text: this.$t(`status.dgi.00`), value: "00" },
    { text: this.$t(`status.dgi.01`), value: "01" },
    { text: this.$t(`status.dgi.05`), value: "05" },
    { text: this.$t(`status.dgi.11`), value: "11" },
  ];
  dgiStatus: string = "all";
  onChange(value: any) {
    this.$emit("on_change_in_dgi_status_select", value);
  }
  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }
}
</script>
