<template>
  <form-field-text
    v-model="invoiceOrder"
    @input:debounce="parseInputOrder"
    v-bind="$attrs"
    label="serie"
  />
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";

@Component
export default class InvoiceInputOrder extends Vue {
  @VModel() invoiceOrder!: any;

  parseInputOrder() {
    let order_serial = "";
    let order_number = "";
    if (this.invoiceOrder.indexOf(" ") >= 0) {
      const values = this.invoiceOrder.split(" ");
      order_serial = values[0];
      order_number = values[1];
    } else {
      if (isNaN(this.invoiceOrder)) {
        const regexed = this.invoiceOrder.split(/(\d+)/);
        order_serial = regexed[0];
        order_number = regexed.length > 0 ? regexed[1] : "";
      } else {
        order_number = this.invoiceOrder;
      }
    }
    this.$emit("change_in_invoice_order", { order_serial, order_number });
  }
}
</script>
