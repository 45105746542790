<template>
  <input-filters
    ref="inputFilters"
    v-model="obFilterData"
    :model-name="sModelFilterKey"
    :headers="headerValueList"
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #invoice_type>
      <invoice-type-select
        v-model="obFilterData.invoiceType"
        @change="onSelectInputType"
        :dense="true"
        :outlined="false"
        :hideLabel="true"
        :only-code="invoiceTypeCodeList"
        solo
      />
    </template>

    <template #is_cash>
      <invoice-is-cash-select
        @on_change_in_is_cash_select="onSelectIsCash"
        :dense="true"
        :outlined="false"
        solo
      />
    </template>

    <template #order_number>
      <invoice-input-order
        @change_in_invoice_order="setInvoiceOrder"
        v-model="invoiceOrder"
        :hide-label="true"
        :outlined="false"
        solo
      />
    </template>

    <template #created_at>
      <date-range
        compact-custom-range
        hasNullable
        :value="dateRange"
        @input="onSetDates"
        :dense="true"
        :outlined="false"
        solo
      />
    </template>

    <template #customer>
      <customer-select
        v-model="obFilterData.customer"
        @change="applyFilters"
        :dense="true"
        :outlined="false"
        solo
        :provider="provider"
        hide-label
      />
    </template>

    <template #currency>
      <currency-select
        v-model="obFilterData.currency"
        company-only
        @change="applyFilters"
        :dense="true"
        :outlined="false"
        solo
        hide-label
      />
    </template>

    <template #total_price_value>
      <input-currency
        v-model="obFilterData.total_price_value"
        :hide-label="true"
        :outlined="false"
        solo
        @currency_input_change="onChangeTotalPrice"
      />
    </template>

    <template #status_dgi>
      <invoice-dgi-status-select
        @on_change_in_dgi_status_select="onChangeDgiStatus"
        :dense="true"
        :outlined="false"
        solo
      />
    </template>

    <template #flag>
      <invoice-flag-select
        @change="onChangeFlag"
        :dense="true"
        :outlined="false"
        solo
      />
    </template>

    <template #actions>
      <div class="text-right">
        <v-btn text color="primary" @click="resetFilters">
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </div>
    </template>
  </input-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InputFilters from "@/components/form/InputFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import InvoiceTypeSelect from "@/modules/invoices/components/InvoiceTypeSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import InvoiceMovementTypeSelect from "@/modules/invoices/components/InvoiceMovementTypeSelect.vue";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import DateRange from "@/components/form/fields/DateRange.vue";
import type { InvoiceType } from "@planetadeleste/vue-mc-gw";
import InvoiceInputOrder from "./InvoiceInputOrder.vue";
import InvoiceIsCashSelect from "./InvoiceIsCashSelect.vue";
import InputCurrency from "./InputCurrency.vue";
import InvoiceDgiStatusSelect from "./InvoiceDgiStatusSelect.vue";
import type { InvoiceTypeCode } from "@/types/utils";
import { map } from "lodash";
import InvoiceFlagSelect from "@/modules/invoices/components/fields/InvoiceFlagSelect.vue";

@Component({
  components: {
    InvoiceFlagSelect,
    InvoiceMovementTypeSelect,
    ProductSelect,
    InputFilters,
    SearchField,
    CustomerSelect,
    InvoiceTypeSelect,
    CurrencySelect,
    DateRange,
    InvoiceInputOrder,
    InvoiceIsCashSelect,
    InputCurrency,
    InvoiceDgiStatusSelect,
  },
})
export default class InvoiceFiltersRow extends Mixins(InvoiceFilters) {
  @Prop() readonly trHeaders!: [];
  @Prop(Array) readonly invoiceType!: InvoiceTypeCode[];
  @Prop(Boolean) readonly provider!: boolean;

  dateRange: string[] | null = [];
  invoiceOrder = "";

  get headerValueList(): string[] {
    return map(this.trHeaders, "value");
  }

  setInvoiceOrder(param: any) {
    this.$set(this.obFilterData, "order_serial", param.order_serial);
    this.$set(this.obFilterData, "order_number", param.order_number);
    this.applyFilters(true);
  }

  resetFilters() {
    this.invoiceOrder = "";
    this.dateRange = null;
    this.obFilterData = this.setDefaults();
  }

  onSelectInputType(obModel: InvoiceType) {
    this.onSelectType(obModel);
    this.applyFilters(true);
  }

  onSelectIsCash(param: any) {
    /*
    all               -> filters[cash]: 1
    cash              -> filters[cash]: 1
    credit            -> filters[credit]: 1
    credit_unpaid     -> filters[credit]: 1 - [unpaid]: 1 - [no_check_skip_balance]: 1
    credit_paid       -> filters[credit]: 1 - [paid]: 1 - [no_check_skip_balance]: 1
    */
    this.$set(this.obFilterData, "cash", null);
    this.$set(this.obFilterData, "credit", null);
    this.$set(this.obFilterData, "unpaid", null);
    this.$set(this.obFilterData, "paid", null);
    this.$set(this.obFilterData, "no_check_skip_balance", null);
    switch (param) {
      case "cash":
        this.$set(this.obFilterData, "cash", 1);
        break;
      case "credit":
        this.$set(this.obFilterData, "credit", 1);
        break;
      case "credit_unpaid":
        this.$set(this.obFilterData, "credit", 1);
        this.$set(this.obFilterData, "unpaid", 1);
        this.$set(this.obFilterData, "no_check_skip_balance", 1);
        break;
      case "credit_paid":
        this.$set(this.obFilterData, "credit", 1);
        this.$set(this.obFilterData, "paid", 1);
        this.$set(this.obFilterData, "no_check_skip_balance", 1);
        break;
    }
    this.applyFilters(true);
  }

  onSetDates(arValue: string[]) {
    this.dateRange = arValue;
    this.$set(this.obFilterData, "created_at", arValue);
    this.applyFilters(true);
  }

  onChangeTotalPrice(value: any) {
    this.$set(this.obFilterData, "total_price_value", value);
    this.applyFilters(true);
  }

  onChangeDgiStatus(value: any) {
    this.$set(this.obFilterData, "status_dgi", value);
    this.applyFilters(true);
  }

  onChangeFlag(value: any) {
    this.$set(this.obFilterData, "flag", value);
    this.applyFilters(true);
  }
}
</script>
