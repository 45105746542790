<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import type { SelectItemData } from "@/types/utils";
import { defaults } from "lodash";

@Component({})
export default class InvoiceFlagSelect extends Vue {
  arOptions: SelectItemData[] = [
    { text: this.$t(`all`), value: "all" },
    { text: this.$t(`status.rya.cancelled`), value: "rya_cancelled" },
    { text: this.$t(`status.signed`), value: "signed" },
  ];
  sStatus: string = "all";

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }

  onChange(value: any) {
    this.$emit("change", value);
  }
}
</script>

<template>
  <v-select
    v-model="sStatus"
    :items="arOptions"
    :menu-props="{ offsetY: true }"
    hide-details="auto"
    item-text="text"
    item-value="value"
    v-bind="obAttrs"
    @change="onChange"
  />
</template>
