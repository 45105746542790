<template>
  <v-select
    v-model="isCashValue"
    :items="arIsCashOptions"
    :menu-props="{ offsetY: true }"
    hide-details="auto"
    item-text="text"
    item-value="value"
    v-bind="obAttrs"
    @change="onChange"
  />
</template>

<script lang="ts">
import { defaults } from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class InvoiceIsCashSelect extends Vue {
  arIsCashOptions: any[] = [
    { text: this.$t("all"), value: "all" },
    { text: this.$t("cash"), value: "cash" },
    { text: this.$t("credit"), value: "credit" },
    // { text: this.$t("credit.paid"), value: "credit_paid" },
    // { text: this.$t("credit.unpaid"), value: "credit_unpaid" },
  ];
  isCashValue = "all";
  onChange(value: any) {
    this.$emit("on_change_in_is_cash_select", value);
  }
  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }
}
</script>
